import { SettingsIcon } from 'icons/SettingsIcon';
import {
  StyledEmail,
  StyledMenu,
  StyledMenuItem,
  StyledMenuTrigger,
  StyledName,
  UserInfoContainer,
} from './styled';

interface Props {
  name: string;
  email: string;
  onLogout: () => void;
}

export const SettingsDropdown = ({ name, email, onLogout }: Props) => (
  <StyledMenu trigger={
    <StyledMenuTrigger>
      <SettingsIcon />
    </StyledMenuTrigger>
  }>
    <StyledMenuItem>
      <UserInfoContainer>
        <StyledName>{name}</StyledName>
        <StyledEmail>{email}</StyledEmail>
      </UserInfoContainer>
    </StyledMenuItem>
    <StyledMenuItem onClick={onLogout}>Logout</StyledMenuItem>
  </StyledMenu>
);