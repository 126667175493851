import styled from "styled-components";
import {
  Menu,
  MenuItem,
} from "@aws-amplify/ui-react";
import { theme } from "theme";

export const StyledMenu = styled(Menu)`
  border-radius: 0 !important;
`;

export const StyledMenuItem = styled(MenuItem)`
  border-radius: 0 !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.colors.shadowsMain[0]};
  
  &:hover {
    background-color: ${theme.colors.shadowsMain[9]};
    color: ${theme.colors.shadowsMain[0]};
  }
  `;

export const StyledName = styled.div`
  font-weight: 600;
`;

export const StyledEmail = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${theme.colors.shadowsMain[3]};
`;

export const StyledMenuTrigger = styled.button`
  all: unset;
  cursor: pointer;
  padding: 10px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
