import styled from "styled-components";
import {
  Authenticator,
  Button,
  Link,
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { theme } from "theme";

export const StyledLoginPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: center / cover no-repeat url(/wavy-background.svg) #000;
  color: ${theme.colors.white};
`;

export const StyledPageHeader = styled.div`
  padding: 40px 0 30px;
`;

export const StyledPageFooter = styled.div`
  width: 478px;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 12px;
`;

export const StyledAuthenticator = styled(Authenticator)`
  --amplify-components-button-primary-background-color: ${theme.colors.primary};
  --amplify-components-button-primary-hover-background-color: ${theme.colors.shadesPrimary[4]};
  --amplify-components-button-primary-active-background-color: ${theme.colors.primary};
  --amplify-components-button-primary-focus-background-color: ${theme.colors.primary};
  --amplify-components-button-border-radius: 6px;
  --amplify-components-authenticator-form-padding: 30px 60px 0px;
  --amplify-space-xl: 60px;

  [data-amplify-router] {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .amplify-tabs {
    display: none;
  }

  .amplify-label {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
  }

  .amplify-divider {
    margin: 40px 0px 10px;

    &:after {
      color: ${theme.colors.shadowsMain[3]}
    }
  }

  .amplify-checkboxfield {
    padding: 10px 0;
    font-size: 14px;
    line-height: 22px;

    .amplify-checkbox__icon {
      background-color: ${theme.colors.primary};
    } 
  }

  .amplify-button--link {
    color: ${theme.colors.primary};
    font-weight: 500;

    &:hover, &:active, &:focus {
      background-color: ${theme.colors.primary}11;
    }
  }

  fieldset {
    margin-bottom: 14px;
  }
`;

export const StyledSignInHeader = styled.div`
  padding: 60px 60px 0px;
`;

export const StyledSignInFooter = styled.div`
  padding: 10px 60px 50px;
`;

export const StyledFederatedLoginButton = styled(Button)`
  font-weight: 500;
  color: ${theme.colors.blue};
  margin-top: 30px;

  span {
    color: ${theme.colors.shadowsMain[0]};
    margin-left: 16px
  };
`;

export const LinkButton = styled.button`
  display: inline;
  color: ${theme.colors.white};
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`;

export const StyledAuthFooter = styled.div`
  font-size: 12px;
  color: #F5EAF9;
  padding: 12px;
  text-align: center;

  button {
    margin-left: 12px;
  }
`;

export const StyledLink = styled(Link)`
  &.amplify-link {
    text-decoration: underline;
    color: ${theme.colors.white};

    &:hover {
      text-decoration: none;
    }
  }
`;
