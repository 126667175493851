import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import {
  AmplifyProvider,
  Authenticator,
} from '@aws-amplify/ui-react';
import { awsExports } from 'config/amplifyCognito';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AmplifyProvider>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </AmplifyProvider>
    </BrowserRouter>
  </React.StrictMode>
);
