import { theme } from "theme";

export const factaApps = [
  {
    app: 'insights',
    name: 'Insights',
    description: 'Understand your business on a deeper level. Facta Insights helps you track your contracts and measure your SaaS metrics.',
    location: process.env.REACT_APP_INSIGHTS_APP_URL,
    chips: ['Contracts', 'SaaS Metrics'],
    chipsColor: theme.colors.primary,
  },
  // {
  //   app: 'core',
  //   name: 'Core',
  //   description: 'Close your books faster with Facta Core modules. Connect to QBO to schedule your prepaids, fixed assets, and deferred revenue.',
  //   location: process.env.REACT_APP_CORE_APP_URL,
  //   chips: ['Prepaids', 'Fixed Assets', 'Deferred Revenue'],
  //   chipsColor: theme.colors.primaryBlue,
  // },
];
