import React, {
  useEffect,
  useState,
} from 'react';
import logo from 'assets/facta-logo.svg';
import {
  LinkButton,
  StyledAuthenticator,
  StyledAuthFooter,
  StyledFederatedLoginButton,
  StyledLink,
  StyledLoginPage,
  StyledPageFooter,
  StyledPageHeader,
  StyledSignInFooter,
  StyledSignInHeader,
} from './styled';
import { AuthFormFields } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';
import {
  DefaultComponents
} from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import {
  Authenticator,
  Button,
  CheckboxField,
  Divider,
  Flex,
  Heading,
  Text,
  useAuthenticator,
  useTheme,
} from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router';
import { theme } from 'theme';
import { GoogleIcon } from 'icons/GoogleIcon';
import { deleteAllCognitoCookies } from 'utils';

export const Login = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const hash = useLocation().hash;
  const sourceApp = queryParams.get('app') || undefined;
  const signUp = hash === '#signUp';

  const formFields: AuthFormFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: 'you@company.com',
      },
      password: {
        labelHidden: false,
        placeholder: 'Type your password',
      }
    },
    signUp: {
      given_name: {
        labelHidden: false,
        label: 'First name',
        placeholder: 'Your first name',
        order: 1
      },
      family_name: {
        labelHidden: false,
        label: 'Last name',
        placeholder: 'Your last name',
        order: 2
      },
      email: {
        labelHidden: false,
        placeholder: 'you@company.com',
        order: 3
      },
      password: {
        labelHidden: false,
        order: 4
      },
      confirm_password: {
        labelHidden: false,
        order: 5
      },
      phone_number: {
        labelHidden: false,
        label: 'Phone (optional)',
        placeholder: '123-456-7890',
        order: 6,
      }
    },
    resetPassword: {
      username: {
        labelHidden: false,
      },
    }
  };
  
  const components: DefaultComponents = {
    Footer() {
      const { route, toSignIn, toSignUp } = useAuthenticator();

      if (route === 'resetPassword' || route === 'confirmResetPassword') return <></>;
  
      return route !== 'signUp'
        ? (
          <StyledAuthFooter>
            Don't have an account?
            <LinkButton 
              onClick={toSignUp}
              data-cy="sign-up-button"
            >
              Sign up
            </LinkButton>
          </StyledAuthFooter>
        )
        : (
          <StyledAuthFooter>
            Already have an account?
            <LinkButton 
              onClick={toSignIn}
              data-cy="sign-in-button"
            >
              Sign in
            </LinkButton>
          </StyledAuthFooter>
        );
    },
    SignIn: {
      Header() {
        return (
          <StyledSignInHeader>
            <Heading level={4}>
              Sign in to your account
            </Heading>
            <StyledFederatedLoginButton
              isFullWidth
              onClick={() => Auth.federatedSignIn({ customState: sourceApp, customProvider: "Google" })}
              data-cy="google-login-button"
            >
              <GoogleIcon />
              <span>Sign In with Google</span>
            </StyledFederatedLoginButton>
            <StyledFederatedLoginButton
              isFullWidth
              onClick={() => Auth.federatedSignIn({ customState: sourceApp, customProvider: "Intuit" })}
              data-cy="intuit-login-button"
            >
              Sign In with Intuit
            </StyledFederatedLoginButton>
            <Divider label="or" />
          </StyledSignInHeader>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <StyledSignInFooter>
            <Button
              isFullWidth
              variation="link"
              onClick={toResetPassword}
              size="small"
              data-cy="forgot-password-button"
            >
              Forgot your password?
            </Button>
          </StyledSignInFooter>
        )
      }
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();
        
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={4}
          >
            Let's get started
          </Heading>
        );
      },
      FormFields() {
        const { validationErrors } = useAuthenticator();
        const [fieldTriggered, setFieldTriggered] = useState(false);
  
        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />
  
            {/* Append & require Terms & Conditions field to sign up  */}
            <CheckboxField
              errorMessage={validationErrors.acknowledgement as string}
              hasError={fieldTriggered && !!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label="Agreed to the terms of use and privacy policy."
              color={theme.colors.primary}
              onClick={() => setFieldTriggered(true)}
            />
          </>
        );
      },
      Footer() {
        return (
          <Flex paddingBottom="40px" />
        )
      }
    },
    ResetPassword: {
      Header() {
        return (
          <>
            <Heading
              padding="30px 0 10px"
              level={4}
            >
              Forgot password
            </Heading>
            <Text
              fontSize="14px"
              lineHeight="22px"
              padding="0 0 20px 0"
            >
              Enter your user account's verified email address and we will send you a code.
            </Text>
          </>
        );
      },
      Footer() {
        return (
          <Flex paddingBottom="20px" />
        )
      }
    },
  };
  
  const services = {
    async validateCustomSignUp(formData: any) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms & Conditions',
        };
      }
    },
  };

  useEffect(() => {
    deleteAllCognitoCookies()
  }, []);
  
  return (
    <StyledLoginPage>
      <StyledPageHeader>
        <img src={logo} alt="Facta" />
      </StyledPageHeader>
      <div>
        <StyledAuthenticator 
          loginMechanisms={['email']}
          signUpAttributes={[
            'given_name',
            'family_name',
            'phone_number'
          ]}
          formFields={formFields}
          components={components}
          services={services}
          initialState={signUp ? 'signUp' : 'signIn'}
        />
      </div>
      <StyledPageFooter>
        <div>
          © Facta, Inc. All Rights Reserved
        </div>
        <div>
          <StyledLink
            href="https://www.facta.io/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </StyledLink>
          {' | '}
          <StyledLink
            href='https://www.facta.io/terms-of-service'
            target="_blank"
          >
            Terms of Service
          </StyledLink>
        </div>
      </StyledPageFooter>
    </StyledLoginPage>
  );
};
