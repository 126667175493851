import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Login } from 'pages/Login';
import { AppSelector } from 'pages/AppSelector';
import { 
  Auth,
  Hub,
} from 'aws-amplify';
import { useLocation } from 'react-router';

Hub.listen('auth', ({payload: {event, data}}) => {
  switch (event) {
    case 'customOAuthState':
      const sourceAppLocation = data;

      if (sourceAppLocation) {
        window.location.href = sourceAppLocation;
      }
      break;
  }
})

const App = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const queryParams = new URLSearchParams(useLocation().search);
  const logout = queryParams.get('logout') || undefined;

  useEffect(() => {
    if (logout) {
      Auth.signOut();
    }
  }, [logout]);
  
  return route === 'authenticated' && !logout ? <AppSelector /> : <Login />;
}

export default App;
