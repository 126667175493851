export const theme = {
  colors: {
    // main: '#000000',
    primary: '#9730C2',
    primaryBlue: '#11ADF8',
    // success: '#71E964',
    white: '#FFFFFF',
    // mainBackground: '#E5E5E5',
    // error: '#F81139',
    // errorBleach: '#FDF3F4',
    // warning: '#F8D011',
    // warningBleach: '#FFFDF3',
    blue: '#006AC0',
    violet: '#613CC6',
  //   shadesSuccess: [
  //     '#0B170A',
  //     '#172F14',
  //     '#2D5D28',
  //     '#448C3C',
  //     '#5ABA50',
  //     '#8DED83',
  //     '#AAF2A2',
  //     '#C6F6C1',
  //     '#E3FBE0',
  //     '#F1FDF0',
  //   ],
    shadesPrimary: [
      '#0F0513',
      '#1E0A27',
      '#3C134E',
      '#5B1D74',
      '#79269B',
      '#AC59CE',
      '#C183DA',
      '#D5ACE7',
      '#EAD6F3',
      '#F5EAF9',
    ],
    shadowsMain: [
      '#171717',
      '#2E2E2E',
      '#464646',
      '#5D5D5D',
      '#747474',
      '#8B8B8B',
      '#A2A2A2',
      '#B9B9B9',
      '#D1D1D1',
      '#ECECEC',
      '#F6F6F6',
    ]
  },
};
