import Cookies from 'js-cookie';

export const deleteAllCognitoCookies = () => {
  const cookies = Object.keys(Cookies.get());

  cookies.forEach((cookieName) => {
    if (cookieName.includes('CognitoIdentityServiceProvider')) {
      Cookies.remove(cookieName, {
        path: '/',
        domain: process.env.REACT_APP_DOMAIN,
      });
    };
  });
};
