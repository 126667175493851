export const awsExports = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['openid','phone','email','profile','aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_URL,
      redirectSignOut: process.env.REACT_APP_URL,
      responseType: "code",
    },
    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN,
      secure: false,
      path: '/',
      expires: 365,
    }
  }
};
