import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { factaApps } from "config/factaApps";
import {
  AppsWrapper,
  ButtonContainer,
  Chip,
  ChipsContainer,
  StyledApp,
  AppSelectorContainer,
  StyledButton,
  TopBar,
  LogoContainer,
  PageWrapper,
  PageContainer,
 } from "./styled";
 import logoWhite from 'assets/facta-logo-white.svg';
import { SettingsDropdown } from "./SettingsDropdown";

export const AppSelector = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const queryParams = new URLSearchParams(useLocation().search);
  const sourceAppLocation = queryParams.get('app');

  const defaultAttributes = {
    given_name: '',
    family_name: '',
    email: 'Not logged in',
  };

  const {
    given_name,
    family_name,
    email,
  } = user.attributes || defaultAttributes;

  const handleLogout = () => {
    signOut();
  };

  const handleGetStarted = (location?: string) => {
    if (location) {
      window.location.href = location;
    }
  }

  useEffect(() => {
    if (sourceAppLocation) {
      window.location.href = sourceAppLocation;
    }
  }, [sourceAppLocation]);

  return sourceAppLocation
    ? null
    : (
      <PageWrapper>
        <PageContainer>
          <TopBar>
            <LogoContainer>
              <img src={logoWhite} alt="Facta" />
              <span>Facta Launcher</span>
            </LogoContainer>
            <SettingsDropdown
              name={`${given_name} ${family_name}`}
              email={email}
              onLogout={handleLogout}
            />
          </TopBar>
          <AppSelectorContainer>
              <h1>Welcome to Facta</h1>
              <h2>Select a product to get started</h2>
              <AppsWrapper>
                {factaApps.map((factaApp) => (
                  <StyledApp key={factaApp.app}>
                    <span>Facta</span>
                    <h1>{factaApp.name}</h1>
                    <ChipsContainer>
                      {factaApp.chips.map((chip) => (
                        <Chip key={chip} color={factaApp.chipsColor}>{chip}</Chip>
                      ))}
                    </ChipsContainer>
                    <p>{factaApp.description}</p>
                    <ButtonContainer>
                      <StyledButton 
                        onClick={() => handleGetStarted(factaApp.location)}
                        data-cy="get-started-button"
                      >
                        Get started
                      </StyledButton>
                    </ButtonContainer>
                  </StyledApp>
                ))}
              </AppsWrapper>
          </AppSelectorContainer>
        </PageContainer>
      </PageWrapper>
    )
}