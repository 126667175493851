import styled from "styled-components";
import { theme } from "theme";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const PageContainer = styled.div`
  overflow: auto;
`;

export const TopBar = styled.div`
  background-color: #201934;
  color: ${theme.colors.white};
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  padding: 0 20px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 30px
  }
`;

export const AppSelectorContainer = styled.div`
  max-width: 1320px;
  margin: 80px auto 0;
  padding: 0 40px;
  overflow-y: auto;

  & > h1 {
    font-size: 60px;
    line-height: 90px;
    font-weight: 500;
    margin: 6px 0;
    color: ${theme.colors.shadesPrimary[0]};
  }

  & > h2 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    margin: 6px 0;
    color: ${theme.colors.shadowsMain[3]};
  }
`;

export const AppsWrapper = styled.div`
  margin: 80px 0;
  display: flex;
  justify-content: space-between;
`;

export const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid black;
  border-radius: 8px;
  padding: 40px;
  color: ${theme.colors.shadesPrimary[0]};
  background: ${theme.colors.white};
  transition: box-shadow 1s ease;
  min-height: 500px;
  width: 100%;
  /* Uncomment when adding second app 
  width: 50%;

  &:first-of-type {
    margin-right: 20px;
  }

  &:last-of-type() {
    margin-left: 20px;
  } */

  & > span {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
  }

  & > h1 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 500;
    margin: 8px 0 24px;
  }

  & > p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color: ${theme.colors.shadowsMain[2]};
    margin: 28px 0;
  }

  &:hover {
    box-shadow: 0px 0px 18px 0px rgba(97, 60, 198, 0.5);
    transition: box-shadow 0.3s ease;
  }
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface ChipProps {
  color: string;
}

export const Chip = styled.div<ChipProps>`
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  border-radius: 4px;
  margin: 0 16px 16px 0;
  padding: 4px 12px;
  background-color: ${(props) => props.color}11;
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
`;

export const StyledButton = styled.button`
  all: unset;
  background-color: ${theme.colors.violet};
  border-radius: 4px;
  color: ${theme.colors.white};
  text-transform: uppercase;
  padding: 11px 18px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    color: ${theme.colors.white};
    opacity: 0.8
  }
`;